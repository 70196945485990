export default function getLink(pages, calltoaction) {
    var bp = false
    if (calltoaction.CallToActionLinkIntern !== null) {
        pages.forEach(p => {
            if (p.strapiId === calltoaction.CallToActionLinkIntern.navigation?.id) {
                bp = p
            } else if (p.items.length > 0) {
                p.items.forEach(s => {
                    if (s.id === calltoaction.CallToActionLinkIntern.navigation?.id) {
                        bp = s
                    }
                })
            }
        })
    }
    return bp
}
export function getNewsLink(pages, news) {
    var bp = false
    if (typeof news !== "undefined" && news.link_intern !== null) {
        pages.forEach(p => {
            if (p.strapiId === news.link_intern.navigation.id) {
                bp = p
            } else if (p.items.length > 0) {
                p.items.forEach(s => {
                    if (s.id === news.link_intern.navigation.id) {
                        bp = s
                    }
                })
            }
        })
    }
    return bp
}

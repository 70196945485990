import React, { Component } from 'react'
import handleViewport from 'react-in-viewport'

const animations = ['jello-horizontal', 'swirl-in-fwd', 'fade-in']
const attentions = ['jello-horizontal-single', 'heartbeat', 'vibrate-1', 'swirl']
const decovariants = ['deco-circle', 'deco-plus', 'deco-x']

class DecorationBlock extends Component {
    constructor(props) {
        super(props)
        this._isMounted = false
        this.state = { redraw: 0, off: true }
    }
    componentWillUnmount() {
        this._isMounted = false
        clearInterval(this.redrawInterval)
    }
    componentDidMount() {
        this._isMounted = true
    }
    getStyle() {
        const { inViewport, enterCount, top, left, bottom, right } = this.props
        var styles = {}
        if (parseInt(top) !== 0) {
            styles['top'] = top
        }
        if (parseInt(left) !== 0) {
            styles['left'] = left
        }
        if (parseInt(bottom) !== 0) {
            styles['bottom'] = bottom
        }
        if (parseInt(right) !== 0) {
            styles['right'] = right
        }
        //Fade in only the first time we enter the viewport
        if (inViewport && enterCount === 1) {
            styles['WebkitTransition'] = 'opacity 0.75s ease-in-out'
        } else if (!inViewport && enterCount < 1) {
            styles['WebkitTransition'] = 'none'
            styles['opacity'] = 0
        }
        return styles
    }
    getClass() {
        const { enterCount, animation, variant } = this.props
        var r = "decoration "
        if (variant) {
            r += variant + " "
        }
        if (enterCount > 0) {
            if (this.state.redraw === 0) {
                r += animations[animation] ? animations[animation] : 'swirl-in-fwd'
                var pause = 3000 + (Math.random() * 3000)
                this.redrawInterval = setInterval(() => {
                    this._isMounted && this.setState((state, props) => {
                        return { redraw: state.redraw + 1, off: !state.off }
                    })
                }, pause)
            } else {
                var rng = Math.floor(Math.random() * attentions.length)
                if (!this.state.off) {
                    r += attentions[rng] ? attentions[rng] : 'heartbeat'
                }
            }
        }
        return r
    }
    render() {
        return (
            <div className={this.getClass()} style={this.getStyle()}></div>
        )
    }
}

const Decoration = handleViewport(DecorationBlock, { rootMargin: '-1.0px' })

export {
    animations,
    decovariants,
    Decoration
}
import React from "react"
import { useLocation } from '@reach/router'
import { Link } from "gatsby"

import FlagDe from '../images/icons/de.svg'
import FlagEn from '../images/icons/gb.svg'

const LanguageSwitch = ({ pages, locale, visibility }) => {
    const location = useLocation()
    var to = { de: '', en: '' }
    if (locale === 'en') {
        to = { de: location.pathname.substring(3), en: location.pathname }
    } else {
        to = { de: location.pathname, en: '/en' + location.pathname }
    }
    return (
        <div className={"languageSwitch " + visibility}>
            {locale === 'en' ? (
                <Link className="dropdown-item" role="button" to={to.de}>
                    DE <FlagDe className="flag" />
                </Link>
            ) : (
                <Link className="dropdown-item" role="button" to={to.en}>
                    EN <FlagEn className="flag" />
                </Link>
            )}
        </div>
    )
}

export default LanguageSwitch
import React, {useState, useEffect} from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useCookies } from "react-cookie"
import { useForm } from "react-hook-form"
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import Logo from '../images/logos-otheb.svg'
import Translations from './translations'
import ReactMarkdown from 'react-markdown'
import OthebMarkdown from './othebmarkdown'

export default function Disclaimer({ pages, locale, textbausteine, showDisclaimer, setShowDisclaimer }) {
    const { register, handleSubmit } = useForm()
    const cookieList = ['essential', 'google']
    const [cookies, setCookie, removeCookie] = useCookies(cookieList)
    const [hidden, setHidden] = useState(true)
    const denyCookies = () => {
        setHidden(true)
        setShowDisclaimer(false)
    }
    useEffect(() => {
        setHidden(false)
    }, []);
    const onSubmit = data => {
        data.essential = true
        cookieList.forEach((c)=>{
            if(data[c]){
                setCookie(c, 1, {
                    path: '/',
                    sameSite: true,
                    secure: true
                })
            } else {
                removeCookie(c,{ path: '/' })
            }
        })
        setShowDisclaimer(false)
    }
    const acceptAll = () =>{
        cookieList.forEach((c)=>{
            setCookie(c, 1, {
                path: '/',
                sameSite: true,
                secure: true
            })
        })
        setShowDisclaimer(false)
    }
    if(!showDisclaimer && (hidden || cookies['essential'] === 1 || cookies['essential'] === "1")){
        return null
    }

    var text = ''
    var baustein = textbausteine.filter((t)=> t.Titel === 'Cookie-Banner')
    if(baustein[0]){
        if (locale === 'en') {
            text = baustein[0].TextEn
        } else {
            text = baustein[0].Text
        }
    }
    return (
        <Form id="disclaimer" onSubmit={handleSubmit(onSubmit)}>
            <Container className="py-4">
                <Row>
                    <Col xs="12" md="4" lg="2">
                        <Logo className="white mb-3"/>
                    </Col>
                    <Col xs="12" md="8" lg="10">
                        <Row>
                            <Col xs="12">
                                <ReactMarkdown components={OthebMarkdown}>
                                    {text}
                                </ReactMarkdown>
                                <AnchorLink to={(locale === 'en' ? '/en/' : '/') + "datenschutz"}>{Translations[locale].more_cookie}</AnchorLink>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs="12">
                                {cookieList.map((c) => {
                                    return (
                                        <Form.Check inline value="1" defaultChecked={c==='essential' || cookies[c] === "1" || cookies[c] === 1} disabled={c==='essential'} key={"cookie"+c} type="switch" label={Translations[locale][c]} {...register(c)} />
                                    )
                                })}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Button variant="secondary" className="me-3 mt-2" onClick={denyCookies}>
                                    {Translations[locale].deny}
                                </Button>
                                <Button type="submit" className="me-3 mt-2">
                                    {Translations[locale].save}
                                </Button>
                                <Button className="me-3 mt-2" onClick={acceptAll}>
                                    {Translations[locale].accept_all}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Form>
    )
}
/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import { Breadcrumb, Container, Row, Col } from "react-bootstrap"

import Navbar from "./navBar"
import Footer from "./footer"

const Layout = ({ pages, activePage, locale, children, isLoading, news, textbausteine }) => {
  var breadcrumb1 = null
  var breadcrumb2 = null

  var titel = 'Titel'
  if (locale === 'en') {
    titel = 'TitelEn'
  }
  if (news?.titel.length > 0){
    breadcrumb1 = <li className="breadcrumb-item"><Link to={news.path}>{news.titel}</Link></li>
  } else {
    pages.forEach(p => {
      if (activePage === p.slug) {
        breadcrumb1 = <li className="breadcrumb-item"><Link to={(locale === 'en' ? '/en' : '') + p.path}>{p.related[titel]}</Link></li>
      } else if (p.items.length > 0) {
        p.items.forEach(s => {
          if (p.path + '/' + activePage === s.path) {
            breadcrumb1 = <li className="breadcrumb-item"><Link to={(locale === 'en' ? '/en' : '') + p.path}>{p.related[titel]}</Link></li>
            breadcrumb2 = <li className="breadcrumb-item"><Link to={(locale === 'en' ? '/en' : '') + s.path}>{s.related[titel]}</Link></li>
          }
        });
      }
    });
  }
  return (
    <StaticQuery
      query={graphql`
       query SiteTitleQuery {
         site {
           siteMetadata {
             title
           }
         }
       }
     `}
      render={data => (
        <>
          <Container fluid className={"px-0 main" + (isLoading ? ' loading' : '')}>
            <Navbar pages={pages} activePage={activePage} locale={locale} />
            <Row>
              <Col>
                <main>
                  {activePage !== '/' && (
                    <Container>
                      <Breadcrumb className="mt-5 breadcrumb">
                        <li className="breadcrumb-item"><Link to={locale === 'en' ? '/en/' : '/'}>Start</Link></li>
                        {breadcrumb1}
                        {breadcrumb2}
                      </Breadcrumb>
                    </Container>
                  )}
                  {children}
                </main>
              </Col>
            </Row>
          </Container>
          <Footer pages={pages} locale={locale} textbausteine={textbausteine}/>
        </>
      )}
    />
  )
}

export default Layout

const Translations = {
    'de':{
        'accept_all': 'Alle akzeptieren',
        'absenden': 'absenden',
        'agb': 'AGB/AEB',
        'alias': 'Name/Alias',
        'betreff': 'Betreff',
        'bewerbung_sent': 'Wir haben Ihre Unterlagen erhalten und werden uns in Kürze bei Ihnen melden',
        'captcha': 'Sichererheitsabfrage: Was ist die Summe von 3+6?',
        'captchaerror': 'Bitte beantworten Sie die Sicherheitsabfrage',
        'contact_success': 'Wir haben Ihre Anfrage empfangen und werden uns in Kürze um Ihr Anliegen kümmern.',
        'continue_reading': 'mehr erfahren',
        'deny': 'Ablehnen',
        'datenschutz': 'Datenschutzerklärung',
        'datenschutzhinweis': 'Die Übermittlung Ihrer Angaben an  uns erfolgt verschlüsselt. Wir versichern Ihnen, dass Ihre Angaben dem Datenschutz unterliegen und ausschließlich intern verwendet werden. Weitere Hinweise zum Datenschutz finden Sie',
        'dragndrop': 'Dateien per Drag & Drop hier ablegen oder',
        'eaef': 'OTHEB ist aktives Mitglied im europäischen Berufsverband für Employee Assistance, dem Employee Assistance European Forum.',
        'email': 'E-Mail',
        'email_format': 'Bitte geben Sie eine gültige E-Mail ein',
        'error_sending_email': 'Beim Versenden der E-Mail ist ein Fehler aufgetreten',
        'essential': 'Essentielle',
        'google': 'Google/Youtube',
        'hier': 'hier',
        'impressum': 'Impressum',
        'is_undefined': 'ist nicht definiert',
        'message': 'Ihre Nachricht',
        'more_cookie': 'Mehr zum Einsatz von Cookies',
        'name': 'Name',
        'nutzungsbedingungen': 'Datenschutzhinweise und Nutzungsbedingungen',
        'olderArticles': 'ältere Artikel laden',
        'please_accept_1': 'Bitte stimmen Sie der',
        'please_accept_2': 'Verwendung von Cookies',
        'please_accept_3': 'zu, um den Inhalt sehen zu können',
        'please_accept_recaptcha': 'zu, um das ReCaptcha geschützte Formular zu sehen',
        'position': 'Position',
        'pressespiegel': 'Pressespiegel',
        'read_more': 'weiter lesen...',
        'required': 'Pflichtfeld',
        'save': 'Speichern',
        'searchfiles': 'Computer durchsuchen',
        'show_disclaimer': 'Cookie-Einstellungen',
        'telefon': 'Telefon',
        'unknown_content_type': 'Unbekannter Inhaltstyp',
        'unternehmen': 'Unternehmen',
        'zum_artikel': 'zum Artikel',
        'prev': 'ältere',
        'next': 'neuere',
        'vorname': 'Vorname'
    },
    'en':{
        'accept_all': 'accept all',
        'absenden': 'send',
        'agb': 'Terms & Conditions',
        'alias': 'Name/Alias',
        'betreff': 'Concerning',
        'bewerbung_sent': 'We have received your application and will get back to you shortly.',
        'captcha': 'Security question: What is the sum of 3+6?',
        'captchaerror': 'Please check the security question',
        'contact_success': 'We have received your request and will get back to you shortly.',
        'continue_reading': 'continue reading',
        'deny': 'deny',
        'datenschutz': 'Data protection',
        'datenschutzhinweis': 'Die Übermittlung Ihrer Angaben an  uns erfolgt verschlüsselt. Wir versichern Ihnen, dass Ihre Angaben dem Datenschutz unterliegen und ausschließlich intern verwendet werden. Weitere Hinweise zum Datenschutz finden Sie',
        'dragndrop': 'Drag & drop your files here or',
        'eaef': 'OTHEB is an active member of the Employee Assistance European Forum.',
        'email': 'E-Mail',
        'email_format': 'Please enter a valid e-mail',
        'error_sending_email': 'Error sending the e-mail',
        'essential': 'essential',
        'google': 'Google/Youtube',
        'hier': 'here',
        'impressum': 'Imprint',
        'is_undefined': 'is undefined',
        'message': 'Your message',
        'more_cookie': 'More about our use of cookies',
        'name': 'Name',
        'nutzungsbedingungen': 'Data protection and terms of use',
        'olderArticles': 'show more articles',
        'please_accept_1': 'Please accept the',
        'please_accept_2': 'usage of cookies',
        'please_accept_3': 'to access this content',
        'please_accept_recaptcha': 'zu, to access the recaptcha protected form',
        'position': 'Position',
        'pressespiegel': 'Press review',
        'read_more': 'read more...',
        'required': 'required',
        'save': 'Save',
        'searchfiles': 'Search your computer',
        'show_disclaimer': 'Cookie-Settings',
        'telefon': 'Telefone',
        'unknown_content_type': 'Unknown content type',
        'unternehmen': 'Company',
        'zum_artikel': 'read article',
        'prev': 'previous',
        'next': 'next',
        'vorname': 'Firstname'
    }
}
export default Translations
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container } from 'react-bootstrap'
import Logo from '../images/logos-otheb.svg'
import Burger from '../images/icons/burger.svg'
import CloseIcon from '../images/icons/close.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

import { Navbar, Nav } from "react-bootstrap"
import LanguageSwitch from "./languageswitch";
const CustomNavbar = ({ pages, activePage, locale }) => {
  var titel = 'Titel'
  if (locale === 'en') {
    titel = 'TitelEn'
  }
  const [largeScreen, setLargeScreen] = useState(typeof window !== 'undefined' ? window.matchMedia("(min-width: 992px)").matches : true);
  const [hovering, setHovering] = useState('');
  const [submenu, setSubmenu] = useState('');

  const openMenu = (slug) => {
    if(largeScreen) {
      setHovering(slug)
    }
  }
  const toggleSubMenu = (slug) => {
    if(submenu === ''){
      setSubmenu(slug)
    } else {
      setSubmenu('')
    }    
  }
  useEffect(() => {
    if (typeof window !== 'undefined'){
      window.addEventListener('resize', function(){
        setLargeScreen(window.matchMedia("(min-width: 992px)").matches)
      })
    }
  }, [setLargeScreen])
  return (
    <Navbar expand="lg" id="site-navbar">
      <Container>
        <Link to={locale === 'en' ? '/en/' : '/'} className="link-no-style logo" title="OTHEB">
          <Navbar.Brand as="span"><Logo /></Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" children={(
          <div>
            <span>MENU</span>
            <Burger />
            <CloseIcon/>
          </div>
        )} />
        <Navbar.Collapse id="basic-navbar-nav">
          <LanguageSwitch locale={locale} visibility="d-block d-lg-none" />
          <ul className="navbar-nav d-flex justify-content-around align-items-lg-center">
            {pages.map((page, j) => (
              page.related.hideInMenu !== true && (
                <li key={`page__${page.slug}`} className={page.items.length > 0 ? 'dropdown' : ''}>
                  {page.items.length === 0 ? (
                      <Link to={(locale === 'en' ? '/en' : '') + page.path} className={"nav-item" + (page.slug === activePage ? ' active' : '')}>
                        <Nav.Link as="span" eventKey={`page-${page.slug}`} onMouseEnter={(e) => { openMenu(page.slug) }}>
                          {page.related[titel]}
                        </Nav.Link>
                      </Link>
                  ) : (
                    <div className={(hovering === page.slug || submenu === page.slug ? ' show' : '')}>
                      <Link aria-haspopup="true" aria-expanded="false" id={"nav-dropdown" + page.slug} to={(locale === 'en' ? '/en' : '') + page.path} className={"nav-item dropdown-toggle " + (page.slug === activePage ? ' active' : '')} onMouseEnter={(e) => { openMenu(page.slug) }}  onClick={(e) => { openMenu('')}}>                       
                          <span className="nav-link">
                            {page.related[titel]}
                            <span role="button" tabIndex={j} className="subtoggle d-inline d-lg-none" onClick={(e) => { e.preventDefault(); toggleSubMenu(page.slug)}} onKeyDown={(e) => { e.preventDefault(); toggleSubMenu(page.slug)}}>
                              <FontAwesomeIcon icon={faChevronDown}/>
                              <FontAwesomeIcon icon={faChevronUp}/>
                            </span>
                          </span>
                      </Link>
                      <div className={"dropdown-menu" + (hovering === page.slug || submenu === page.slug ? ' show' : '')} onMouseLeave={(e) => { openMenu('')}} aria-hidden="true" >
                        <Container className="d-flex">
                          {page.items.map((subpage, j) => (
                            subpage.related.hideInMenu !== true && (
                              <Link key={"subnav-" + j} className="dropdown-item" to={(locale === 'en' ? '/en' : '') + subpage.path}>
                                {subpage.related[titel]}
                              </Link>
                            )
                          ))}
                        </Container>
                      </div>
                    </div>
                  )}
                </li>
              )
            ))}
            <li className="d-none">
              <LanguageSwitch locale={locale} visibility="d-none d-lg-block" />
            </li>
          </ul>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default CustomNavbar

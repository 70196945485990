import { Link } from "gatsby"
import React, {useState} from "react"
import { Container, Row, Col } from 'react-bootstrap'
import Logo from '../images/logos-otheb.svg'
import LinkedIn from '../images/icons/icons-linked-in.svg'
import Xing from '../images/icons/icons-xing.svg'
import { StaticImage } from "gatsby-plugin-image"
import Translations from './translations'
import Disclaimer from './disclaimer'
export default function Footer({ pages, locale, textbausteine }) {
    const [showDisclaimer, setShowDisclaimer] = useState(false)
    const today = new Date()
    var titel = 'Titel'
    if (locale === 'en') {
      titel = 'TitelEn'
    }
    return (
        <Row className="px-0 footer">
            <div className="vidcontainer"><video autoPlay muted loop>
                <source src="/images/bg/wellen.mp4" type="video/mp4" />
                <track src="captions.vtt" kind="captions" srcLang="de" label="german captions"></track>
            </video></div>
            <div className="vidoverlay"></div>
            <Col className="footer-col">
                <Container>
                    <Row>
                        <Col xs="12" sm="6" lg="2">
                            <Row>
                                <Col xs="3" sm="12">
                                    <Link className="logo" to={locale === 'en' ? '/en/' : '/'}>
                                        <Logo />
                                    </Link>
                                </Col>
                                <Col xs="9" sm="12" className="address">
                                    <p>OTHEB GmbH<br />Norwegenkai 1<br />D - 24143  Kiel</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <div className="iconBox">
                                        <a href="https://de.linkedin.com/company/otheb-gmbh" target="_blank" rel="noreferrer">
                                            <LinkedIn />
                                        </a>
                                        <a href="https://www.xing.de" target="_blank" rel="noreferrer">
                                            <Xing />
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" sm="6" lg="4">
                            <ul className="zweiSpalten">
                                {pages.map((page) => (
                                    <div key={"footerpage"+page.slug}>
                                     {page.related.showInFooter && (
                                        <li key={`page__${page.slug}`}>
                                            <Link to={(locale === 'en' ? '/en' : '') + page.path}>
                                                {page.related[titel]}
                                            </Link>
                                        </li>
                                    )}
                                    {page.items.map((subpage, j) => (
                                        subpage.related.showInFooter && (
                                            <li key={`subpage__${subpage.slug}`}>
                                                <Link to={(locale === 'en' ? '/en' : '') + subpage.path}>
                                                    {subpage.related[titel]}
                                                </Link>
                                            </li>
                                        )
                                    ))}
                                    </div>
                                ))}
                            </ul>
                        </Col>
                        <Col sm="12" lg="6" className="eaef">
                            {false && (
                                <Row>
                                    <Col xs="1" lg="3" xl="2">
                                        <StaticImage
                                            src="../images/icons/logos-eaef.png"
                                            alt="EAEF Logo"
                                            placeholder="blurred"
                                            layout="fixed"
                                            width={54}
                                            height={54}
                                        />
                                    </Col>
                                    <Col xs="11" lg="9" xl="10" className="claim">
                                        <p>{Translations[locale].eaef}</p>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                    <Row className="smallPrint">
                        <Col md="12" lg="6" className="order-md-2 order-lg-1 d-none d-md-block">
                            <p>{today.getFullYear()} © OTHEB · <Link to={locale === 'en' ? '/en/impressum' : '/impressum'}>{Translations[locale].nutzungsbedingungen}</Link>
                            
                            </p>
                        </Col>
                        <Col md="12" lg="6" className="juraLinks order-md-1 order-lg-2 order-sm-1">
                            <ul>
                                <li><Link to={locale === 'en' ? '/en/impressum' : '/impressum'}>{Translations[locale].impressum}</Link></li>
                                <li><Link to={locale === 'en' ? '/en/datenschutz' : '/datenschutz'}>{Translations[locale].datenschutz}</Link></li>
                                <li><button onClick={()=>setShowDisclaimer(!showDisclaimer)}>{Translations[locale].show_disclaimer}</button></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </Col>
            <Disclaimer locale={locale} textbausteine={textbausteine} showDisclaimer={showDisclaimer} setShowDisclaimer={setShowDisclaimer}/>
        </Row>
    )
}
import * as React from "react"
import { Link } from "gatsby"
import PlusIcon from '../images/icons/icons-plus.svg'

const OthebMarkdown = {
    a({node, inline, className, children, ...props}) {
        if (node.properties.href?.substring(0,1) === '/') {
            return (
                <Link className={className} to={node.properties.href}>
                    {children}
                </Link>
            )
        } else {
            return (
                <a className={className} href={node.properties.href} target="_blank" rel="noreferrer">
                    {children}
                </a>
            )
        }
    },
    li({node, inline, className, children, ...props}) {
        return (
            <li className={className}>
                <PlusIcon /> {children}
            </li>
        )
    }
}
export default OthebMarkdown